import { provideWorker } from '@bcf-vanilla-ts-v1-platforms/platform-worker/browser/providers';
import { registerEnvCommon } from '@bcflit-env/env-token';
import { envCommon } from '@bcflit-env/kingspin/env.prod';
import '@bcflit-polyfills';
import { registerElement } from '@tmf-shared-classes/tmf-lit-element';
import { AppElement } from '../../app/app';
import { settingsProviders } from '../../app/settings-providers';

async function main(): Promise<void> {
  registerEnvCommon(envCommon);
  registerElement(AppElement);
  settingsProviders();
  const appRuntimeId: number = new Date().getTime();
  const worker: Worker = new Worker(new URL('./main.worker.ts', import.meta.url), { type: 'module' });
  provideWorker(worker, appRuntimeId, 'mobile-web');
}
main();
