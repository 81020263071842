import { provideSvgIconAppComputedStylesService } from '@bcflit-ds-components/base/svg-icon/svg-icon-app-computed-styles.service';
import { provide } from '@lit/context';
import { TmfLitElement } from '@tmf-shared-classes/tmf-lit-element';
import {
  ActivatedBottomSheet,
  activatedBottomSheetContext
} from '@tmf-shared-platform/activated-bottom-sheet/activated-bottom-sheet';
import { ActivatedLang, activatedLang, activatedLangContext } from '@tmf-shared-platform/activated-lang/activated-lang';
import { ActivatedModal, activatedModalContext } from '@tmf-shared-platform/activated-modal/activated-modal';
import { ActivatedRoute, activatedRouteContext } from '@tmf-shared-platform/activated-route/activated-route';
import { ActivatedUrl, activatedUrlContext } from '@tmf-shared-platform/activated-url/activated-url';
import { HistoryUrl, historyUrlContext } from '@tmf-shared-platform/history-url/history-url';
import { Router } from '@tmf-shared-platform/router/internal';
import {
  RouterNavigatorService,
  provideRouterNavigatorService
} from '@tmf-shared-platform/router/router-navigator.service';
import { routerPageScrollerService } from '@tmf-shared-platform/router/router-page-scroller.service';
import { ScrollRestorer, scrollRestorerContext } from '@tmf-shared-platform/scroll-restorer/scroll-restorer';
import { property } from 'lit/decorators.js';

export class BcflitBaseAppWithInstrumentations extends TmfLitElement {
  @provide({ context: activatedLangContext })
  @property({ attribute: false })
  public activatedLang: ActivatedLang = activatedLang;

  @provide({ context: activatedRouteContext })
  @property({ attribute: false })
  public activatedRoute: ActivatedRoute = new ActivatedRoute();

  @provide({ context: activatedModalContext })
  @property({ attribute: false })
  public activatedModal: ActivatedModal = new ActivatedModal(this.activatedRoute);

  @provide({ context: activatedBottomSheetContext })
  @property({ attribute: false })
  public activatedBottomSheet: ActivatedBottomSheet = new ActivatedBottomSheet(this.activatedRoute);

  @provide({ context: activatedUrlContext })
  @property({ attribute: false })
  public activatedUrl: ActivatedUrl = new ActivatedUrl();

  @provide({ context: historyUrlContext })
  @property({ attribute: false })
  public historyUrl: HistoryUrl = new HistoryUrl();

  @provide({ context: scrollRestorerContext })
  @property({ attribute: false })
  public scrollRestorer: ScrollRestorer = new ScrollRestorer();

  public router: Router | undefined;

  public override connectedCallback(): void {
    super.connectedCallback();
    const routerNavigatorService: RouterNavigatorService = provideRouterNavigatorService();
    routerNavigatorService.bindRouter(this.router!);
    routerNavigatorService.bindHistoryUrl(this.historyUrl);
    routerPageScrollerService.init();
    this.activatedRoute.addRouter(this.router!);
    provideSvgIconAppComputedStylesService().setComputedStyles(getComputedStyle(this));
    document.querySelector('.splash-screen')?.remove();
  }
}
