/* eslint-disable @typescript-eslint/naming-convention */
import { DiToken } from '@bcf-vanilla-ts-v1-shared/di/di-token';
import { CpWizardPostFormLayoutConfig } from './types';

export const CP_WIZARD_POST_FORM_LAYOUT_CONFIG: DiToken<CpWizardPostFormLayoutConfig> =
  new DiToken<CpWizardPostFormLayoutConfig>('cpWizardPostFormLayoutConfig');

export const CP_WIZARD_POST_SHOW_PROMOCODE_FIELD_ON_INIT: DiToken<boolean> = new DiToken<boolean>(
  'cpWizardPostShowPromocodeFieldOnInit'
);
