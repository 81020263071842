import { EnvCms } from '@bcf-vanilla-ts-v1-api-access/config-tokens/env-cms';
import { EnvCp } from '@bcf-vanilla-ts-v1-api-access/config-tokens/env-cp';
import { EnvCommon } from '@bcflit-env/types';
import { envBaseCommon } from './env.base';

export const envCp: EnvCp = {
  userApiUrl: 'https://user.kingspin.io/',
  walletApiUrl: 'https://wallet.kingspin.io/',
  paymentApiUrl: 'https://payment.kingspin.io/'
};

export const envCms: EnvCms = {
  cmsApiUrl: 'https://cms.lvbet.lv/'
};

// // eslint-disable-next-line @typescript-eslint/typedef
export const envCommon: EnvCommon = {
  ...envBaseCommon
};
