import { registerElement } from '@tmf-shared-classes/tmf-lit-element';
import { RouteConfig } from '@tmf-shared-platform/router/internal';
import { html } from 'lit';

export function appRoutes(langs: string[]): RouteConfig[] {
  return [
    ...langs.map(
      (lang: string) =>
        ({
          path: `/${lang}{/*}?`,
          render: () => html`<bcflit-ksd-lang-route .appLang="${lang}"></bcflit-ksd-lang-route>`,
          enter: async () => {
            registerElement((await import('./lang-route/lang-route.element')).LangRouteElement);
            return true;
          }
        }) as RouteConfig
    )
  ];
}
